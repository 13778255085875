import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { servicesList } from "../../utils/services";
import ServicePage from "../../containers/ServicePage";

class ServiceScreen extends React.Component {
  constructor(props) {
    super(props);

    let service = servicesList.find(function(element) {
      return element.key === props.serviceKey;
    });

    this.state = {
      service
    };
  }

  render() {
    return (
      <div>
        <Header />
        <ServicePage service={this.state.service} />
        <Footer />
      </div>
    );
  }
}

export default ServiceScreen;
