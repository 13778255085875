export const servicesList = [
  {
    url: "/services/physio",
    key: "physio",
    title: "Physiotherapy",
    subtitle: "Manual Therapy & Exercise",
    description:
      "Our Vitality Physiotherapists are highly trained at using their hands to diagnose and treat restrictions in joints, soft tissue and movement. In modern manual therapy, physiotherapists use their hands to put pressure on muscle tissue and mobilize or manipulate joints in an attempt to decrease pain caused by muscle spasm, muscle tension, and joint dysfunction. Other manual therapy techniques our Vitality Physiotherapists provide include Active Release Techniques, Graston\
        (instrument-assisted soft tissue mobilization), and muscle energy techniques. They also use a modern rehabilitation approach using exercises to address dysfunctional movement, control and strength. Using the advanced Vitality Rehab approach our Therapists will be able to help identify the root cause of the condition and prescribe a program that will return you back to state you were previous to the injury and better.",
    image: require("../images/services-physio.jpg"),
    thumb: require("../images/services-physio-thumb.jpg")
  },
  {
    url: "/services/chiropractic",
    key: "chiropractic",
    title: "Chiropractic",
    subtitle: "Chiropractic care is a drug-free, manual approach to health.",
    description:
      "Our Vitality Chiropractors use our cutting-edge Vitality Rehab approach providing body analysis, analyzing the framework of your body’s foundation and identifying the synergy by assessing your neuromuscular control. This allows for treatment of acute and chronic injuries, poor posture as well as occupational and sports injuries\
      <br/><br/>\
        In evaluating a patient, the Vitality Chiropractors look to the spine and extremities including nerves, muscles, tendons, ligaments and fascia. Chiropractic adjustments are used to increase mobility of joints and surrounding tissues as well as to decrease the pain and muscular spasms associated with dysfunction. Medical Acupuncture, various myofascial techniques (ART, Graston etc.) and Dynamic Neuromuscular Stabilization (DNS) are some specialized techniques utilized in the Vitality rehabilitation process. Chiropractors are trained to initiate exercise and rehab programs as well as to provide nutrition and lifestyle counseling.",
    image: require("../images/services-chiro.jpg"),
    thumb: require("../images/services-chiro-thumb.jpg")
  },
  {
    url: "/services/massage",
    key: "massage",
    title: "Massage Therapy",
    description: "Our registered massage therapist (RMT) works together with our Vitality Therapist to complement your rehabilitation plan to achieve optimal results on your road to wellness. Our RMT uses different hands on techniques with a non-invasive approach to reduce muscle tightness, relieve stress, promote the healing process and enhance your function. Let’s keep it simple… Massage therapy is good for you and makes you feel better!",
    image: require("../images/services-massage.jpg"),
    thumb: require("../images/services-massage-thumb.jpg")
  },
  {
    url: "/services/face-massage",
    key: "face-massage",
    title: "Face & Scalp Therapy",
    subtitle: "The benefits of a face and scalp massage shouldn’t be missed, and here's why",
    description: "As a technologically focused society, we tend to live in the upper part of our body. We spend a lot of our day in our head; staring at screens, crinkling our faces, straining our eyes, scrunching our forehead as we think, game and type away. It is these very actions that make this one of the best places to help relax the mind, the body and decrease stress.</br>\
    </br>\
    <span class='large'>Benefits Include:</span></br>\
    \
    • <span class='blue'>Lymphatic Drainage</span> - Did you know there are 43 muscles in the face? Massage increase the flow of blood and oxygen to muscles, including facial muscles. This can be highly effective due to its ability to prevent sluggish circulation and remove unwanted toxins. This reduces inflammation and and keeps our complexion healthy.</br></br>\
    • <span class='blue'>TMJ relief (Temporal Mandibular Joint)</span> - Does your jaw click, or does your jaw lock? Do you clench or grind your teeth when you concentrate or at night? Massaging your head and face especially jaw and temporal region will help reduce those symptoms. These areas can get tense and develop trigger points leaving you with a lot of aches. Massage will loosen up at the tension and you feel great.</br></br>\
    • <span class='blue'>Headache Relief</span> - Muscle tension is one of the leading causes of some headaches. Relaxing the muscles of the scalp, face and neck can bring soothing relief to headache sufferers in just one session.</br></br>\
    • <span class='blue'>Clears the Sinuses</span> - Massaging the acupressure points can help open and relieve pressure in the sinuses. Perfect for when you are trying to get over the last bits of a cold!",
    image: require("../images/services-face-massage.jpg"),
    thumb: require("../images/services-face-massage-thumb.jpg")
  },
  {
    url: "/services/acupuncture",
    key: "acupuncture",
    title: "Neurofunctional Acupuncture/Dry Needling",
    subtitle:
      "Functional Dry Needling (FDN) is a technique used to treat myofascial pain with a solid filiform needle.",
    description:
      "This technique targets muscles that contain trigger points and are reproducing your pain. Functional Dry Needling focuses not on pain, but on movement. Evaluation and assessment of dysfunctional movement is imperative for the therapist to successfully affect movement. Treating the neuromuscular tissue that is not allowing or promoting proper movement is the goal of Functional Dry Needling. This method is used as a tool within your treatment plan and can produce quicker results.",
    image: require("../images/services-acupuncture.jpg"),
    thumb: require("../images/services-acupuncture-thumb.jpg")
  },
  {
    url: "/services/shockwave",
    key: "shockwave",
    title: "Shockwave Therapy",
    subtitle:
      "Shockwave therapy (SWT) is a multidisciplinary device used in orthopaedics, physiotherapy & sports medicine.",
    description:
      "Shockwave is an acoustic wave which carries high energy to painful spots and myoskeletal tissues with subacute, subchronic and chronic conditions. The energy promotes regeneration and reparative processes of the bones, \
        tendons and other soft tissues. Its main assets are fast pain relief and mobility restoration. Shockwave Therapy has been shown to be a great alternative to surgeries as an ideal treatment to speed up recovery and cure various indications causing acute or chronic pain. Literature has shown that SWT can help with many chronic and acute conditions such as the ones listed below, but you should ask your therapist if SWT can be helpful during your recovery!\
        <br/><br/>\
        SWT can be beneficial in treating:<br/>\
        • Jumper’s Knee<br/>\
        • Painful Shoulder<br/>\
        • Tennis Elbow<br/>\
        • Heel Spur<br/>\
        • Plantar Fasciitis<br/>\
        • Insertional Pain<br/>\
        • Chronic Tendinopathy (Tendonitis)<br/>\
        • Medial Tibial Stress Syndrome (Shin Splints)<br/>\
        • Calcifications<br/>\
        • Hip Pain<br/>\
        • Stress Fractures",
    image: require("../images/services-shockwave.jpg"),
    thumb: require("../images/services-shockwave-thumb.jpg")
  },
  {
    url: "/services/vestibular",
    key: "vestibular",
    title: "Vestibular Rehabilitation",
    subtitle:
      "Vestibular rehabilitation therapy (VRT) is an evidence based treatment protocol that is focused on specialized exercises prescribed by a specially trained physiotherapists.",
    description:
      "Your vestibular system and your eyes have a direct connection with each other. Problems with your vestibular system can result in changes to your eye reflexes, producing abnormal eye motion (called Nystagmus). Closely examining these nystagmus patterns of your eyes allows us to perform a more detailed assessment resulting in a more accurate diagnosis. An astonishing 50% of all adults will experience dizziness at some point in their life and affect their quality of life and day to day activities, as well as increasing the risks of falls.<br/><br/>\
      Vertigo and dizziness symptoms most often area a result of a dysfunction of the vestibular organ, which is a small but important structure in your inner ear. The vestibular organ is critical for balance, coordination, and movement and when it’s impaired, patients can experience spinning (vertigo), dizziness, nausea, imbalance, problems with vision, and lightheadedness, anxiety, among other often debilitating symptoms. Benign paroxysmal positional vertigo (BPPV) is  the most common condition for dizziness which affects the inner ear. People with this condition get dizzy or have vertigo when turning their head, bending forward, lying down, looking up and/or rolling in bed.",
    image: require("../images/services-vestibular.jpg"),
    thumb: require("../images/services-vestibular-thumb.jpg")
  },
  {
    url: "/services/concussion",
    key: "concussion",
    title: "Concussion Rehabilitation",
    description:
      "Recovering from a concussion can be difficult and frustrating at times. Doesn't matter if you are an athlete or the average person. Our Expert Vitality Physiotherapist understands that concussion affects each person differently, and all treatments are individualized, and patient centred.  We use Shift Concussion Management protocols including the IMPACTⓒ neurocognitive test which is FDA approved, for assessment and treatment that have been developed by the best emerging evidence in concussion care. The best Physiotherapy treatments are geared to each individual’s specific needs based on the mechanism of injury, injury type, and assessment findings. They may include manual therapy to restore any joint problems in the neck, upper back and shoulder area, exercises to regain mobility, strength and balance, acupuncture, and visual motor and vestibular retraining exercises to restore normal brain function. Management strategies are used to limit impairments while expediting recovery. Call us now for a free 15-minute phone consultation if you suffer with an acute or chronic post-concussion injury.",
    image: require("../images/services-concussion.jpg"),
    thumb: require("../images/services-concussion-thumb.jpg")
  },
  {
    url: "/services/pelvic",
    key: "pelvic",
    title: "Pelvic Floor Physiotherapy",
    subtitle:
      "Pelvic floor (PF) physiotherapy is private, confidential, and is an conservative treatment for assessment and treatment of conditions including:",
    description:
      "Diastasis rectus abdominus, incontinence and bladder control issues, low back pain, pelvic pain and discomfort, abdominal pain, caesarean section (c-section) scarring, muscle imbalances and pain during or after postpartum sexual intercourse.<br/><br/>\
      PF physiotherapy also involves pre- and postnatal screening and exercise prescription. Studies have proven that pelvic floor physiotherapy is an highly effective treatment method and alternative to “just adapting to live with it”. Our Vitality Pelvic Floor Physiotherapist is highly trained with special certifications to be able to perform this type of assessment and treatments. Both men and women can benefit from these services.<br/>\
      If you are unsure if pelvic floor physiotherapy can help or have any questions, call us now and arrange a free 15-minute phone consultation with our pelvic floor physiotherapist.",
    image: require("../images/services-pelvic.jpg"),
    thumb: require("../images/services-pelvic-thumb.jpg")
  },
  {
    url: "/services/orthotics",
    key: "orthotics",
    title: "Custom Orthotics",
    subtitle: "Custom Orthotics made for your foot.",
    description:
      "Our Vitality Therapist will carry out a gait analysis as well as a subjective and objective assessment to identify the needs for a custom orthotic. Orthotics can help reduce pain and correct biomechanical deficiencies. The Vitality Therapist will also educate you on the underlying cause of your pain and will provide you with therapeutic exercises to target the deficiencies.",
    image: require("../images/services-orthotics.jpg"),
    thumb: require("../images/services-orthotics-thumb.jpg")
  },
  {
    url: "/services/braces",
    key: "braces",
    title: "Braces & Custom Knee Braces",
    subtitle:
      "At Vitality Physiotherapy and Wellness Centre we offer a wide variety of off the shelf and custom orthopedic braces.",
    description:
      "We only use the best performing braces that are made for different various types of injuries, so we can have you performing at your best no matter your level of activity. Our braces include knee, ankle, wrist, elbow, back as well as offering compression sleeves for your knees and ankles. We find top quality braces that will best suit you, as we know finding the right brace on your own can be difficult. These braces can help you continue with your activities without limiting, while you recover from your injury. All braces are measured to ensure we have proper fitting. Bracing can be used for various injuries for protection of the joints, improving function, and facilitating muscle activation to promote correct movement.<br/><br/>\
      <span class='blue'>Our braces and custom braces target a variety of musculoskeletal conditions including:</span><br/>\
      • Knee injuries (i.e. Patellar tendonitis, Patellofemoral syndrome, Patellar dysfunction)<br/>\
      • Knee ligamentous injuries (ACL, PCL, MCL, LCL)<br/>\
      • Knee osteoarthritis<br/>\
      • Ankle Sprains and Ankle instability<br/>\
      • Plantar fasciitis and Achilles tendinitis<br/>\
      • Elbow injuries (Tennis and Golfer’s elbow)<br/>\
      • Carpal tunnel syndrome<br/>\
      • De Quervain Tenosynovitis<br/>\
      • Wrist or thumb tendinitis<br/><br/>\
      Insurance companies do cover bracing, for more information and If you are unsure if a brace could benefit you, give us a call and speak with our physiotherapists who would be happy to help and guide you.",
    image: require("../images/services-braces.jpg"),
    thumb: require("../images/services-braces-thumb.jpg")
  }
];
