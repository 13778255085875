import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../images/logo.png";
import Close from "../../images/icon_close.png";

import "./styles.css";

class ConsumerSideNav extends React.Component {
  render() {
    let visibility = "hide";

    if (this.props.sideNavVisibility) {
      visibility = "show";
    }

    return (
      <div
        id="flyoutMenu"
        onClick={this.props.handleClick}
        className={visibility}
      >
        <div className="side-nav-container">
          <div className="side-nav-header">
            <Link
              to="/"
              className="side-nav-header-logo-container"
              style={{ textDecoration: "none" }}
            >
              <img
                src={Logo}
                alt="buzzyn logo"
                className="side-nav-header-logo-img"
              />
            </Link>
            <img
              src={Close}
              alt="close menu"
              className="side-nav-header-close"
            />
          </div>

          <div className="side-nav-menu">
            <Link to="/" style={{ textDecoration: "none" }}>
              <div className="side-nav-menu-item">
                <h1 className="side-nav-text">HOME</h1>
              </div>
            </Link>

            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <div className="side-nav-menu-item">
                <h1 className="side-nav-text">ABOUT US</h1>
              </div>
            </Link>

            <Link to="/services" style={{ textDecoration: "none" }}>
              <div className="side-nav-menu-item">
                <h1 className="side-nav-text">SERVICES</h1>
              </div>
            </Link>

            <Link to="/locations" style={{ textDecoration: "none" }}>
              <div className="side-nav-menu-item">
                <h1 className="side-nav-text">LOCATIONS</h1>
              </div>
            </Link>

            {/* <Link to="/blog" style={{ textDecoration: "none" }}>
              <div className="side-nav-menu-item">
                <h1 className="side-nav-text">BLOG</h1>
              </div>
            </Link> */}

            <Link to="/faq" style={{ textDecoration: "none" }}>
              <div className="side-nav-menu-item">
                <h1 className="side-nav-text">FAQ</h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerSideNav;
