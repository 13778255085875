import React from "react";
import "./styles.css";
import MultiLocationMap from "../../components/MultiLocationMap";

import IconPhone from "../../images/icon_phone.png";
import IconLocation from "../../images/icon_location.png";
import IconEmail from "../../images/icon_email.png";
import IconHours from "../../images/icon_hours.png";

export default class LocationsPage extends React.PureComponent {
  render() {
    let locations = [
      {
        name: "Main Location",
        coordinates_lat: 45.283268,
        coordinates_lon: -75.685786
      },
      // {
      //   name: "Satellite Location",
      //   coordinates_lat: 45.346406,
      //   coordinates_lon: -75.627213
      // }
    ];

    return (
      <React.Fragment>
        <div className="locations-container">
          <div className="locations-list-wrapper">
            <div className="locations-list-container">
              <div className="locations-list-location-container">
                <div className="locations-list-location-container-header">
                  <h1>Main Location</h1>
                </div>

                <div className="locations-list-location-section">
                  <div className="locations-list-location-section-header">
                    <img src={IconPhone} alt="Phone" />
                    <a href="tel:6134257700">(613) 425-7700</a>
                  </div>
                </div>

                <div className="locations-list-location-section">
                  <div className="locations-list-location-section-header">
                    <img src={IconEmail} alt="Email" />
                    <a href="mailto: info@vitalitypt.ca">info@vitalitypt.ca</a>
                  </div>
                </div>

                <div className="locations-list-location-section">
                  <div className="locations-list-location-section-header">
                    <img src={IconLocation} alt="Location" />
                    <h1>
                      4184 Spratt Road
                      <br />
                      Gloucester, ON K1V 0Z6
                    </h1>
                  </div>
                </div>

                <div className="locations-list-location-section">
                  <div className="locations-list-location-section-header">
                    <img src={IconHours} alt="Hours" />

                    <h1>Hours Of Operation</h1>
                  </div>
                  <h2 className="locations-list-location-section-content">
                    <span className="bold">Mon-Thurs:</span> 7am - 8pm
                  </h2>
                  <h2 className="locations-list-location-section-content">
                    <span className="bold">Fri:</span> 7am - 5pm
                  </h2>
                  <h2 className="locations-list-location-section-content">
                    <span className="bold">Sat:</span> by appointment only
                  </h2>
                </div>
              </div>
              {/* <div className="locations-list-location-container">
                <div className="locations-list-location-container-header">
                  <h1>Satellite Location</h1>
                </div>
                <div className="locations-list-location-container-subheader">
                  <h1>Located inside Greco Fitness</h1>
                </div>

                <div className="locations-list-location-section-header">
                  <img src={IconLocation} alt="Location"/>
                  <h1>
                    1650 Queensdale Ave #1,
                    <br />
                    Gloucester, ON K1T 1J4
                  </h1>
                </div>
              </div> */}
            </div>
          </div>

          <div className="locations-map-container">
            <MultiLocationMap
              locations={locations}
              zoom={2}
              minZoom={2}
              maxZoom={17}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
