import React from "react";
import "./styles.css";
import Collapsible from "react-collapsible";

const faqs = [
  {
    question: "WHAT TRAINING DO VITALITY THERAPISTS HAVE?",
    answer:
      "All of our Expert Vitality Therapists come from one of three licensed professional backgrounds: either physiotherapy, chiropractic, or massage therapy. In addition to their professional training, they are required to go through a rigorous internal training on the Vitality Method and System when they join our team of therapists. Are therapists are also encouraged to take continuing education to ensure we always offer the most up-to-date rehabilitation plans."
  },
  {
    question: "HOW IS VITALITY DIFFERENT THAN OTHER THERAPIES?",
    answer:
      "The uniqueness of Vitality is that we understand that movement is the foundation of everything that we do. Many therapies ignore this part, and work only passively and only focus on pain. With Vitality, we start by asking specific question to find movement dysfunctions, we then look at the body holistically, and finally we figure out the structures causing these dysfunctions. This helps us look strategically and systematically at identifying the root cause of any pain, or areas holding you back from performance improvements. From this point,we establish a rehab plan and use the best tools at our disposal to create lasting meaningful change. While other clinics use machines as their go to, we believe exercises and the synergy initiating proper movement is the key to pain free living."
  },
  {
    question: "IS VITALITY PAINFUL?",
    answer:
      "Pain is very personal and it will depend a lot on your individual tolerance and experience with being treated. Initially your body will need to adapt to the techniques the therapists are performing. At Vitality, we have learned based on hours upon hours of perfecting our method that to create permanent change in tissue, at times techniques are required that can be painful. Most clients describe it as “the best pain ever” as they feel the tension release and already start to experience what it’s like to move without pain. If at any point in time you’re feeling uncomfortable, just let your Vitality Therapist know."
  },
  {
    question: "CAN YOU/DO YOU FIX (INSERT CONDITION/INJURY/ETC)",
    answer: "The answer is yes, we have collectively seen thousands of patients and have experience with many variations of dysfunctional muscular patterns and injuries. If you have anything extremely exotic or have any questions you want answered about a specific condition or injury before coming to your first Vitality session, feel free to contact us and we’ll connect you with the appropriate Vitality Therapist who can answer your questions for you."
  },
  {
    question: "HOW LONG WILL EACH SESSION LAST?",
    answer: "Your initial assessment and treatment takes approximately one hour, and follow up visits are anywhere between 30 to 60 minutes in length depending on a variety of factors."
  },
  {
    question: "WILL I GET TREATED DURING MY INITIAL ASSESSMENT?",
    answer: "Definitely. While we start your initial assessment with a thorough Body Analysis and muscle testing, we also find a lot of value in continually re-assessing throughout your treatment, getting you off the table to walk around, testing functional positions, assessing postural changes, and ultimately seeking out the root cause of your dysfunction through constant testing and re-testing through your entire Vitality Rehab program."
  },
  {
    question: "HOW MANY SESSIONS WILL I NEED?",
    answer: "Although your Vitality Therapist will be able to provide you with a much more accurate timeline following your initial assessment, as a general rule of thumb the following holds true: For more minor, non-chronic injuries or movement challenges we can see dramatic improvement within 4-6 sessions. For more major or chronic injuries or movement issues we ballpark around 10-15 sessions. Finally, for severe or long-term chronic issues, it may take up to 20 sessions or even more to see a significant resolution of your symptoms. These are very general estimates and every patient case varies. The good news you will often you’ll feel improvement from the very first session. Your therapist will work with you to create a program that will be tailored to your needs during your initial session, so you’ll have an idea of your roadmap once you come in."
  },
  {
    question: "HOW CAN VITALITY HELP ME IF I AM NOT IN PAIN?",
    answer: "When we talk about moving you for a better tomorrow, we mean it. Don’t wait for an injury to build up. Our modern lifestyle habits of being glued to a chair or couch for many hours a day or in front of a computer screen does not allow our body to work or maintain itself the way it’s supposed to. Due to many of these habits we start to develop dysfunctional movement patterns. Add in some weekend warrior activity after a stressful week with very little movement, and you’ve got a recipe for potential future injury and pain. On the other hand, if we take the time on a monthly basis to address your muscular tension, improve your movement patterns, address lifestyle factors, and ensure that we’re dealing intelligently with tweaks and aches before they become a major problem, you’re going to move better, move faster, and live a longer, pain-free life."
  },
  {
    question: "DO I NEED A DOCTOR TO REFER ME TO VITALITY?",
    answer: "Patients do not need a referral to be treated by any of our Vitality therapists. However, some private insurance companies do require a doctor’s referral for either physiotherapy, chiropractic and massage therapy. Please check with your private insurance company before making an appointment."
  },
  {
    question: "IS VITALITY COVERED BY MY INSURANCE?",
    answer: "Our team of licensed physiotherapists, chiropractors and registered massage therapists and can be claimed under Physiotherapy, Chiropractic, or Registered Massage through your extended health coverage with your private insurance provider."
  },
  {
    question: "DO YOU BILL DIRECTLY?",
    answer: "Depending on your insurance company we can direct bill. At your first appointment we will be able to identify if we are able to direct bill for your specific insurance or not. If you would like to know prior to your appointment just give us a call and our experience administrators can help answer those questions."
  },
  {
    question: "HOW MUCH COVERAGE DO I HAVE?",
    answer: "Every policy is different so you will need to check with your insurance policy. As a service provider, we do not have access to that information. We can always help guide you in our clinic to get the answer you need."
  },
  {
    question: "WILL YOU TELL ME IF I RUN OUT OF COVERAGE?",
    answer: "Unfortunately, as a service provider, we do not have access to that information. The only information we are provided is how much is being covered for the appointment that we submit."
  },
  {
    question: "DO YOU ACCEPT MOTOR VEHICLE ACCIDENTS (MVA)",
    answer: "Yes! We do accept Motor Vehicle Accident (MVA) cases. If you were injured through a MVA, please let us know when you’re booking your appointment. We require your claim and policy number, name of your adjudicator and your auto insurance company. In most cases, we can bill the auto insurer directly."
  },
  {
    question: "DO YOU ACCEPT WORKPLACE INJURY CASES?",
    answer: "Unfortunately, we do not accept any WSIB claims or cases."
  },
];

class FAQPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: -1
    };
  }


  handleFAQClick = index => {
    if (this.state.openIndex === index) {
      this.setState({ openIndex: -1 });
    } else {
      this.setState({ openIndex: index });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="faq-list-container">
          <div className="faq-list">
            <div className="faq-list-header">
              <h1 className="faq-title">FAQ</h1>
              <h1 className="faq-subtitle">We're here to help.</h1>
            </div>
            {faqs.map((faq, index) => {
              let trigger = (
                <div className="faq-trigger">
                  <h1 className="faq-question">{faq.question}</h1>
                  <span className="faq-trigger-icon">&#9660;</span>
                </div>
              );
              let triggerOpen = (
                <div className="faq-trigger">
                  <h1 className="faq-question">{faq.question}</h1>
                  <span className="faq-trigger-icon">&#9650;</span>
                </div>
              );
              return (
                <Collapsible
                  open={this.state.openIndex === index}
                  trigger={trigger}
                  triggerWhenOpen={triggerOpen}
                  transitionTime={140}
                  accordionPosition={index}
                  handleTriggerClick={this.handleFAQClick}
                  key={index}
                >
                  <p className="faq-answer">{faq.answer}</p>
                </Collapsible>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FAQPage;
