import React from "react";
import "./styles.css";

import WelcomeImage from "../../../images/about-us-1.jpg";

const Welcome = () => (
  <React.Fragment>
    <div className="welcome-container">
      <div className="welcome-image-container">
        <img src={WelcomeImage} alt=""/>
      </div>
      <div className="welcome-text-container">
        <h1 className="welcome-title">
          Welcome to the Vitality Physiotherapy and Wellness Centre
        </h1>
        <h2 className="welcome-subtitle">Our Vision</h2>
        <p className="welcome-details">
          Vitality Physiotherapy and Wellness Centre prides itself on our rehab
          system. This system focuses on helping each individual reach their
          full potential of living a healthy, pain-free life through movement.
          Movement is the keystone of our effort, and we strive to educate each
          person on the importance of being in-touch with their body. We
          understand life gets busy, and there are challenges along the way, but
          we believe that this should never be a barrier in fulfilling your own
          personal wellness. Our vision is to help each and every individual
          reach their full potential through our Vitality rehab process.
        </p>
      </div>
    </div>
  </React.Fragment>
);

export default Welcome;
