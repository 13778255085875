import React from "react";
import "./styles.css";

import VitalityLogoV from "../../../images/logo-v.png";
import MyScrollAnimation from "../../../utils/MyScrollAnimation";

const ServicesWelcome = () => (
  <React.Fragment>
    <div className="services-welcome-container">
      <div className="services-welcome-image-container">
        <img src={VitalityLogoV} className="services-welcome-image" alt="Welcome"/>
      </div>
      <div className="services-welcome-text-container">
        <MyScrollAnimation className="services-welcome-title" animateIn='slideInRight' delay={0}>
          This Is Vitality
        </MyScrollAnimation>
        <MyScrollAnimation className="services-welcome-subtitle" animateIn='slideInRight' delay={600}>
          Performed only by licensed physical therapists, chiropractors, and
          massage therapists, you’re in good hands with our Vitality Therapists.
        </MyScrollAnimation>
      </div>
    </div>
  </React.Fragment>
);

export default ServicesWelcome;
