import React from "react";
import "./styles.css";

export default class BookNowFloaterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }

  handleScroll = () => {
    if (this.state.scrollTimeOut) {
      clearTimeout(this.state.scrollTimeOut);
    }
    var scrollTimeOut = setTimeout(() => {
      this.setState({
        visible: true
      });
    }, 1000);

    this.setState({
      scrollTimeOut,
      visible: false
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div
        className={
          this.state.visible
            ? "floater-button-container"
            : "floater-button-container floater-button-container--hidden"
        }
      >
        <a className="floater-button" href="https://vitalitypt.janeapp.com/">
          <span>BOOK NOW</span>
        </a>
      </div>
    );
  }
}
