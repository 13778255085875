import React from "react";
import "./styles.css";

import Icon1 from "../../images/icon-what-is-vitality-1.png";
import Icon2 from "../../images/icon-what-is-vitality-2.png";
import Icon3 from "../../images/icon-what-is-vitality-3.png";
import Icon4 from "../../images/icon-what-is-vitality-4.png";
import Icon5 from "../../images/icon-what-is-vitality-5.png";
import Icon6 from "../../images/icon-what-is-vitality-6.png";
import MyScrollAnimation from "../../utils/MyScrollAnimation";

const WhatIsVitality = () => (
  <React.Fragment>
    <div className="home-section">
      <div className="home-section-text-container">
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h1 className="home-section-title">WHAT IS VITALITY</h1>
        </MyScrollAnimation>
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h2 className="home-section-subtitle">The New Standard In Care</h2>
        </MyScrollAnimation>
      </div>
      <div className="what-is-vitality-steps">
        <div className="what-is-vitality-step-container" id="step-1">
          <img className="what-is-vitality-step-image" src={Icon1} alt=""/>
          <h2 className="what-is-vitality-step-text">Expert Advice</h2>
        </div>
        <div className="what-is-vitality-step-container" id="step-2">
          <img className="what-is-vitality-step-image" src={Icon2} alt=""/>
          <h2 className="what-is-vitality-step-text">1-on-1 Treatment</h2>
        </div>
        <div className="what-is-vitality-step-container" id="step-3">
          <img className="what-is-vitality-step-image" src={Icon3} alt=""/>
          <h2 className="what-is-vitality-step-text">Hands-on Care</h2>
        </div>
        <div className="what-is-vitality-step-container" id="step-4">
          <img className="what-is-vitality-step-image" src={Icon4} alt=""/>
          <h2 className="what-is-vitality-step-text">Custom Programs</h2>
        </div>
        <div className="what-is-vitality-step-container" id="step-5">
          <img className="what-is-vitality-step-image" src={Icon5} alt=""/>
          <h2 className="what-is-vitality-step-text">Movement Education</h2>
        </div>
        <div className="what-is-vitality-step-container" id="step-6">
          <img className="what-is-vitality-step-image" src={Icon6} alt=""/>
          <h2 className="what-is-vitality-step-text">Private Rooms</h2>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default WhatIsVitality;
