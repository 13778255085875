import React from 'react';
import './styles.css';
import { Link } from "react-router-dom";

const ServiceLink = (props) => (
  <Link to={props.service.url} className="service-link-container">
    <img  className="service-link-image"src={props.service.thumb} alt="Service thumbnail"/>
    <div  className="service-link-title">{props.service.title}</div>
  </Link>
);

export default ServiceLink;
