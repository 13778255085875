import React from "react";
import "./styles.css";

import ImageBodyAnalysis from "../../images/icon_body_analysis.jpg";
import ImageFramework from "../../images/icon_framework.jpg";
import ImageSynergy from "../../images/icon_synergy.jpg";

import MyScrollAnimation from "../../utils/MyScrollAnimation";

const HowItWorks = () => (
  <React.Fragment>
    <div className="home-section">
      <div className="home-section-text-container">
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h1 className="home-section-title">HOW IT WORKS</h1>
        </MyScrollAnimation>
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h2 className="home-section-subtitle">The Primer</h2>
        </MyScrollAnimation>
        <p className="home-section-description">
          The primer is where your Vitality Method begins. One of our Vitality
          Therapist will commence the appointment by asking you specific
          questions to start identifying movements and behaviors resulting in
          dysfunctions.
        </p>
      </div>

      <div className="how-it-works-grid">
        <div className="how-it-works-grid-cell">
          <img src={ImageBodyAnalysis} alt=""/>
          <div className="how-it-works-grid-cell-text-container">
            <h1>Body Analysis</h1>
            <p>
              Following The Primer, your Vitality Therapist will commence The
              Body Analysis to identify postural and movement deficits and
              revealing the root cause of your pain.
            </p>
          </div>
        </div>

        <div className="how-it-works-grid-cell">
          <img src={ImageFramework} alt=""/>
          <div className="how-it-works-grid-cell-text-container">
            <h1>Framework</h1>
            <p>
              We observe your body’s foundation and its relationship with the
              environment. You may have developed soft tissue adaptations that
              may be limiting your quality of movement.
            </p>
          </div>
        </div>

        <div className="how-it-works-grid-cell">
          <img src={ImageSynergy} alt=""/>
          <div className="how-it-works-grid-cell-text-container">
            <h1>Synergy</h1>
            <p>
              We evaluate your articulations by assessing the neuromuscular
              control you have over your joints.
            </p>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default HowItWorks;
