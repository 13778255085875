import React from "react";
import { Link, NavLink } from "react-router-dom";

import SideNav from "../SideNav";
import Logo from "../../images/logo.png";
import MenuBar from "../../images/icon_side_menu.png";
import "./styles.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      homePage: false,
      businessPage: false,
      aboutPage: false,
      discoverPage: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }

  render() {
    return (
      <div className="navbar-container">
        <div className="navbar-top-container">
          <div className="navbar-top-container-max-width">
            <div className="navbar-top-left-container">
              <div className="navbar-sm-container">
                <div className="navbar-sm">
                  <img
                    src={MenuBar}
                    alt="menu bar"
                    onClick={this.handleClick}
                    className="navbar-sm-menu-btn"
                  />
                </div>
              </div>
              <Link to="/" className="navbar-logo-container">
                <img
                  src={Logo}
                  alt="buzzyn logo"
                  className="navbar-logo-image"
                />
              </Link>
            </div>

            <div className="navbar-menu-container">
              <div className="navbar-menu">
                <NavLink
                  className="navbar-navlink"
                  activeClassName="navbar-navlink-active"
                  exact
                  to="/"
                >
                  HOME
                </NavLink>
                <NavLink
                  className="navbar-navlink"
                  activeClassName="navbar-navlink-active"
                  exact
                  to="/about-us"
                >
                  ABOUT US
                </NavLink>
                <NavLink
                  className="navbar-navlink"
                  activeClassName="navbar-navlink-active"
                  exact
                  to="/services"
                >
                  SERVICES
                </NavLink>
                <NavLink
                  className="navbar-navlink"
                  activeClassName="navbar-navlink-active"
                  exact
                  to="/locations"
                >
                  LOCATIONS
                </NavLink>
                {/* <NavLink
                  className="navbar-navlink"
                  activeClassName="navbar-navlink-active"
                  exact
                  to="/blog"
                >
                  BLOG
                </NavLink> */}
                <NavLink
                  className="navbar-navlink"
                  activeClassName="navbar-navlink-active"
                  exact
                  to="/faq"
                >
                  FAQ
                </NavLink>
              </div>
            </div>

            <div className="navbar-contact-container">
              <a className="navbar-book-now-container" href="https://vitalitypt.janeapp.com/">
                <h1>Book Now</h1>
              </a>
              <a className="navbar-phone-container" href="tel:6134257700">
                <h2>(613) 425-7700</h2> 
              </a>
            </div>
          </div>

          <div className="navbar-gradient" />
        </div>

        <div className="sidenav-container">
          <SideNav
            handleClick={this.handleClick}
            sideNavVisibility={this.state.visible}
          />
        </div>
      </div>
    );
  }
}

export default Header;
