import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import ServicesPage from '../../containers/ServicesPage';

const ServicesScreen = () => (
  <div>
    <Header/>
    <ServicesPage/>
    <Footer/>
  </div>
);

export default ServicesScreen;
