import React from "react";
import FacebookIcon from "../../images/icon_social_facebook.png";
import InstagramIcon from "../../images/icon_social_instagram.png";

import "./styles.css";
import { servicesList } from "../../utils/services";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="footer-container">
    <div className="footer-content-container">
      <div className="links-list">
        <Link className="links-list-item" to="/home" key="home">
          〉 home
        </Link>
        <Link className="links-list-item" to="/about-us" key="about-us">
          〉 about us
        </Link>
        <Link className="links-list-item" to="/locations" key="locations">
          〉 locations
        </Link>
        <Link className="links-list-item" to="/blog" key="blog">
          〉 blog
        </Link>
        <Link className="links-list-item" to="/faq" key="faq">
          〉 faq
        </Link>
      </div>

      <div className="services-list">
        <h1 className="services-list-title">Services</h1>
        {servicesList.map(function(item, index) {
          return (
            <Link
              className="services-list-service-item"
              to={item.url}
              key={item.url}
            >
              {"〉    " + item.title}
            </Link>
          );
        })}
      </div>

      <div className="footer-right">
        <h1>Follow Us</h1>
        <div className="footer-social-media-container">
          <a
            href="https://www.facebook.com/VitalityPhysioWC/"
            className="footer-social-media-button"
          >
            <img src={FacebookIcon} alt="Facebook"/>
          </a>
          <a
            href="https://www.instagram.com/vitalityphysio/"
            className="footer-social-media-button"
          >
            <img src={InstagramIcon} alt="Instagram"/>
          </a>
        </div>

        <h1>Contact Us</h1>
        <a
          href="tel:6134257700"
          className="footer-phone-container"
        >
          <h3>(613) 425-7700</h3>
        </a>
        <a
          href="https://vitalitypt.janeapp.com/"
          className="footer-book-now-button"
        >
          {/* <div className="footer-book-now-button"> */}
          <h3>MAKE AN APPOINTMENT </h3>
          {/* </div> */}
        </a>
      </div>
    </div>

    <div className="footer-copyright">
      <p>Vitality Physiotherapy © 2019</p>
    </div>
  </footer>
);

export default Footer;
