import React from "react";
import "./styles.css";

import Welcome from "./Welcome";
import ServiceLink from "../../components/ServiceLink";

import { servicesList } from "../../utils/services";

const ServicesPage = () => (
  <React.Fragment>
    <Welcome />
    <div className="services-grid-container">
      {servicesList.map(function(item, index) {
        return <ServiceLink service={item} key={index}/>;
      })}
    </div>
  </React.Fragment>
);

export default ServicesPage;
