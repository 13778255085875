import React from "react";
import "./styles.css";
import VitalityButton from "../VitalityButton";
import MyScrollAnimation from "../../utils/MyScrollAnimation";

const BookNextSession = () => (
  <React.Fragment>
    <div className="home-section">
      <div className="home-section-text-container">
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h1 className="home-section-title">BOOK YOUR NEXT SESSION</h1>
        </MyScrollAnimation>
      </div>
      <VitalityButton
        title="MAKE AN APPOINTMENT"
        to="https://vitalitypt.janeapp.com/"
      />
    </div>
  </React.Fragment>
);

export default BookNextSession;
