import React from 'react';

class MultiLocationMap extends React.Component {

  componentDidMount() {
    this.initMap();
  }

  initMap = () => {
    const { locations } = this.props;

    let mapOptions = {
      zoom: 13,
      // center: new window.google.maps.LatLng(45.314259, -75.654972),
      center: new window.google.maps.LatLng(45.283268, -75.685786),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: this.props.disableDefaultUI,
      gestureHandling: this.props.gestureHandling,
      zoomControl: this.props.zoomControl,
    };

    let map = new window.google.maps.Map(
      document.getElementById('map'),
      mapOptions
    );

    for (let i = 0; i < locations.length; i++) {
      let location = locations[i];
      let myLatLng = new window.google.maps.LatLng(
        location.coordinates_lat,
        location.coordinates_lon,
        location.name
      );

      //info window
      let infowindow = new window.google.maps.InfoWindow({
        content: location.name
      });

      //set marker icon
      const baseIcon = {
        url: `/icon_marker_small.png`,
        scaledSize: new window.google.maps.Size(50, 50), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(25, 50) // anchor
      };

      //set marker
      let marker = new window.google.maps.Marker({
        position: myLatLng,
        map: map,
        zIndex: location[3],
        icon: baseIcon
      });

      //add listener for info window
      marker.addListener('click', function () {
        infowindow.open(map, marker);
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedLocation !== this.props.selectedLocation) {
      this.fitMap(this.state.map, nextProps.selectedLocation);
      this.setMarkers(this.state.map, nextProps.locations, nextProps.selectedLocation);
    }
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div id="map" style={{ width: '100%', height: '100%' }} />
      </div>
    );
  }
}

export default MultiLocationMap;
