import React from "react";
import "./styles.css";
import Testimonial from "./Testimonial";
import Carousel from "nuka-carousel";
import VitalityButton from "../../components/VitalityButton";
import MyScrollAnimation from "../../utils/MyScrollAnimation";

const Testimonials = () => (
  <React.Fragment>
    <div className="home-section">
      <div className="home-section-text-container">
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h1 className="home-section-title">TESTIMONIALS</h1>
        </MyScrollAnimation>
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <p className="home-section-description">
            Here's what our clients think about us
          </p>
        </MyScrollAnimation>
      </div>

      <Carousel
        className="reviews-carousel"
        framePadding="0 60px 60px 60px"
        wrapAround={true}
      >
        <Testimonial
          title="Mitko A."
          review="Great place, great service! I went there because of a knee injury. I only deal with Mina and Courtney, they are very professional, very knowledgeable. The centre is equipped with the latest gadgets and technology. The receptionist Trish is very prompt and flexible with providing the best hours that suit my schedule. I would highly recommend their services."
        />
        <Testimonial
          title="Tatiana A."
          review="The Vitality Centre is an amazing team! From the first conversation on the phone with Trish when I was in excruciating pain, they did their best to see me as promptly as possible. Throughout my treatment they always maintained patient centered care. I had heard other people say about other physiotherapy centers (not Vitality) that they did not like their physiotherapists because they would only get hooked to machines and left alone for an hour. During my sessions at Vitality I always had one on one treatment either by Andrew or his wonderful and knowledgeable assistant Courtney. My progress was evident even from the first session. I am very thankful to have had this wonderful team help me get my health on track to be able to continue working in a career I love. I highly recommend them!"
        />
        <Testimonial
          title="Patrick G."
          review="Vitality is amazing! The entire staff is welcoming, nice and knowledgeable. You can tell that they truly care and just want to help people in any way possible. Dr. Jonathan Steen was great to work with, very down to earth and overall a great person. We worked on my shoulder which I have been suffering pain with for quite some time. While the treatment sessions were great, the best part was working on a strategic long term plan to improving the underlying problem, understanding why this is happening and what I can do to help change it. With plenty of easy to follow exercises for at home and at the gym, I am happy (but a little sad) that I am leaving the clinic feeling better than I have ever felt."
        />
        <Testimonial
          title="Caline G."
          review="Such great energy at Vitality! The staff is so kind and welcoming. It's hard to find a good massage therapist, but Janice is one that you want to keep coming back to! She's so professional, and very specific in her treatment -- she explains what she's doing and why. She also has unique techniques (her scalp/face massage is amazing!) that leave me feeling relaxed from head to toe. I feel so refreshed after yesterday's massage and can't wait to get back in the gym!"
        />
        <Testimonial
          title="Julie B."
          review="Andrew is a fantastic physiotherapist! Very attentive and so professional! I could barely go a day without back pain and now I feel 100 percent better. I’m going to keep up my daily exercises to keep up with this incredible progress! I really loved my experience here and I’ve gotten incredible results. The entire staff is fantastic!"
        />
      </Carousel>

      <VitalityButton
        title="Read More Reviews"
        to="https://search.google.com/local/reviews?placeid=ChIJFd0GuOvizUwRq_UdtZzqmeo"
      />
    </div>
  </React.Fragment>
);

export default Testimonials;
