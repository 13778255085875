import React from "react";
import "./styles.css";

const VitalityButton = (props) => (
    <a className="button-link" href={props.to}>
      <span className="button-span">{props.title}</span>
    </a>
);

export default VitalityButton;
