import React from 'react';

import Header from '../../components/Header';

import Footer from '../../components/Footer';
import FAQPage from '../../containers/FAQPage';

const FAQScreen = () => (
  <div>
    <Header/>
    <FAQPage/>
    <Footer/>
  </div>
);

export default FAQScreen;
