/* eslint react/prop-types: 0 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import HomeScreen from "../screens/HomeScreen";
import AboutUsScreen from "../screens/AboutUsScreen";
import ServicesScreen from "../screens/ServicesScreen";
import LocationsScreen from "../screens/LocationsScreen";
import FAQScreen from "../screens/FAQScreen";
import ServiceScreen from "../screens/ServiceScreen";

export default () => (
  <Switch>
    <Route exact path="/" component={HomeScreen} />
    <Route exact path="/about-us" component={AboutUsScreen} />
    <Route exact path="/services" component={ServicesScreen} />
    <Route exact path="/locations" component={LocationsScreen} />
    <Route exact path="/faq" component={FAQScreen} />
    <Route exact path="/services/physio" component={() => <ServiceScreen serviceKey={'physio'} />} />
    <Route exact path="/services/chiropractic" component={() => <ServiceScreen serviceKey={'chiropractic'} />} />
    <Route exact path="/services/massage" component={() => <ServiceScreen serviceKey={'massage'} />} />
    <Route exact path="/services/face-massage" component={() => <ServiceScreen serviceKey={'face-massage'} />} />
    <Route exact path="/services/acupuncture" component={() => <ServiceScreen serviceKey={'acupuncture'} />} />
    <Route exact path="/services/shockwave" component={() => <ServiceScreen serviceKey={'shockwave'} />} />
    <Route exact path="/services/vestibular" component={() => <ServiceScreen serviceKey={'vestibular'} />} />
    <Route exact path="/services/concussion" component={() => <ServiceScreen serviceKey={'concussion'} />} />
    <Route exact path="/services/pelvic" component={() => <ServiceScreen serviceKey={'pelvic'} />} />
    <Route exact path="/services/orthotics" component={() => <ServiceScreen serviceKey={'orthotics'} />} />
    <Route exact path="/services/braces" component={() => <ServiceScreen serviceKey={'braces'} />} />
  </Switch>
);
