import React from "react";

import ScrollAnimation from "react-animate-on-scroll";

export default class MyScrollAnimation extends React.Component {
  render() {
    return (
      <ScrollAnimation animateIn={this.props.animateIn} {...this.props} animateOnce={true} delay={this.props.delay}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={this.props.delay}>
          {this.props.children}
        </ScrollAnimation>
      </ScrollAnimation>
    );
  }
}
