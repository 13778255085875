import React from "react";
import "./styles.css";

import ApproachImage1 from "../../images/approach-1.jpg";
import ApproachImage2 from "../../images/approach-2.jpg";
import ApproachImage3 from "../../images/approach-3.jpg";
import MyScrollAnimation from "../../utils/MyScrollAnimation";

const Approach = () => (
  <React.Fragment>
    <div className="home-section">
      <div className="home-section-text-container">
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h1 className="home-section-title">THE VITALITY REHAB APPROACH</h1>
        </MyScrollAnimation>
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <p className="home-section-description">
            Following the Body Analysis your Vitality therapist will discuss our
            Vitality Rehab Approach. The Approach will consist of objectives to
            correct deficiencies identified during your Body Analysis to get you
            moving for a better tomorrow
          </p>
        </MyScrollAnimation>
      </div>

      <div className="approach-images">
        <div className="approach-image-container" id="image1">
          <img className="approach-image" src={ApproachImage1} alt=""/>
        </div>
        <div className="approach-image-container" id="image2">
          <img className="approach-image" src={ApproachImage2} alt=""/>
        </div>
        <div className="approach-image-container" id="image3">
          <img className="approach-image" src={ApproachImage3} alt=""/>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default Approach;
