import React from 'react';

import Header from '../../components/Header';

import AboutUsPage from '../../containers/AboutUsPage';

import Footer from '../../components/Footer';

const AboutUsScreen = () => (
  <div>
    <Header/>
    <AboutUsPage/>
    <Footer/>
  </div>
);

export default AboutUsScreen;
