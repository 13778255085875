import React from "react";
import "./styles.css";

import WhatIsVitality from "../../components/WhatIsVitality";
import Approach from "../../components/Approach";
import Testimonials from "../../components/Testimonials";
import HowItWorks from "../../components/HowItWorks";
import WhyCustomersChoose from "../../components/WhyCustomersChoose";
import VitalityButton from "../../components/VitalityButton";
import BookNextSession from "../../components/BookNextSession";
import MyScrollAnimation from "../../utils/MyScrollAnimation";

import ChoiceAwardIcon from "../../images/icon_choice_award.png";

const HomePage = () => (
  <div>
    <div className="section-home-hero-top">
      <div className="section-home-hero-top-text-container">
        <MyScrollAnimation animateIn="slideInRight" delay={0}>
          <h1 className="section-home-hero-top-title">
            Moving You,
            <br />
            For A <span className="green">Better Tomorrow</span>
          </h1>
        </MyScrollAnimation>
        <MyScrollAnimation animateIn="slideInRight" delay={200}>
          <h2 className="section-home-hero-top-subtitle">
            Vitality is a team of expert physiotherapists, chiropractors, and
            massage therapists that uses the Vitality Rehab Methods to provide a
            premium experience for our clients to optimize movement and recover
            their pain-free life.
          </h2>
        </MyScrollAnimation>
        <MyScrollAnimation animateIn="slideInRight" delay={300}>
          <VitalityButton
            title="MAKE AN APPOINTMENT"
            to="https://vitalitypt.janeapp.com/"
          />
        </MyScrollAnimation>
      </div>
      <img className="section-home-hero-top-choice-award" src={ChoiceAwardIcon} alt="2019 Consumers' Choice Award"/>
    </div>

    <div className="white-background">
      <WhatIsVitality />

      <HowItWorks />

      <Approach />

      <WhyCustomersChoose />

      <div className="all-services-button">
        <VitalityButton title="Services" to="/#/services" />
      </div>

      <Testimonials />

      <BookNextSession />
    </div>
  </div>
);

export default HomePage;
