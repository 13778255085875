import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import ScrollToTop from "./utils/ScrollToTop";
import BookNowFloaterButton from "./components/BookNowFloaterButton";

import history from "../src/utils/history";

import Routes from "../src/routes";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import Loader from "./components/Loader";

import "animate.css/animate.min.css";

import ReactGA from "react-ga";

//Google tracking
ReactGA.initialize("UA-112137124-1", {
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});
history.listen(location => {
  ReactGA.pageview(location.pathname);
}); //listen to changes in route

ReactGA.pageview(history.location.pathname); //track first page

ReactDOM.render(
  <React.Suspense fallback={<Loader />}>
    <IntlProvider locale="en">
      <Router history={history}>
        <ScrollToTop>
          <Routes />
          <BookNowFloaterButton />
        </ScrollToTop>
      </Router>
    </IntlProvider>
  </React.Suspense>,
  document.getElementById("root")
);
serviceWorker.unregister();
