import React from "react";
import "./styles.css";
import Stars from "../../../images/icon_five_stars.png";


const Testimonial = (props) => (
  <React.Fragment>
    <div className="testimonial">
        <h1 className="testimonial-title">
            {props.title}
        </h1>
        <img className="testimonial-stars" src={Stars} alt="stars"/>
        <p className="testimonial-review">
            {props.review}
        </p>
    </div>
  </React.Fragment>
);

export default Testimonial;
