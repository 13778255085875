import React from 'react';

import Header from '../../components/Header';

import HomePage from '../../containers/HomePage';

import Footer from '../../components/Footer';

const HomeScreen = () => (
  <div>
    <Header/>
    <HomePage/>
    <Footer/>
  </div>
);

export default HomeScreen;
