import React from "react";
import "./styles.css";

import Image from "../../images/about-us-1.jpg";
import ImageGym from "../../images/icon_why_gym.png";
import ImageReviews from "../../images/icon_why_reviews.png";
import ImagePrivateRooms from "../../images/icon_why_private_rooms.png";
import ImageEco from "../../images/icon_why_eco.png";
import ImageExpertTherapy from "../../images/icon_why_expert_therapy.png";
import ImageChoiceAward from "../../images/icon_why_choice_award.png";
import MyScrollAnimation from "../../utils/MyScrollAnimation";

const WhyCustomersChoose = () => (
  <React.Fragment>
    <div className="home-section">
      <div className="home-section-text-container">
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <h1 className="home-section-title">Why Clients Choose Us</h1>
        </MyScrollAnimation>
        <MyScrollAnimation animateIn="slideInUp" delay={0}>
          <p className="home-section-description">
            Our one focus is you. We are committed to getting you back to being
            active, achieving your goals and providing you with the best
            experience on your road to wellness.
          </p>
        </MyScrollAnimation>
      </div>

      <div className="why-customers-choose-wrapper">
        <div className="why-customers-choose-grid">
          <div className="why-customers-choose-grid-cell">
            <img src={ImageExpertTherapy} alt=""/>
            <p>Expert Therapists</p>
          </div>
          <div className="why-customers-choose-grid-cell">
            <img src={ImagePrivateRooms} alt=""/>
            <p>Private Rooms</p>
          </div>
          <div className="why-customers-choose-grid-cell">
            <img src={ImageGym} alt=""/>
            <p>Open Gym Concept</p>
          </div>
          <div className="why-customers-choose-grid-cell">
            <img src={ImageChoiceAward} alt=""/>
            <p>Consumers Choice Award For Top Rated Clinic In Ottawa</p>
          </div>
          <div className="why-customers-choose-grid-cell">
            <img src={ImageReviews} alt=""/>
            <p>5 Star Google Reviews</p>
          </div>
          <div className="why-customers-choose-grid-cell">
            <img src={ImageEco} alt=""/>
            <p>Direct Billing & Paperless Transactions</p>
          </div>
        </div>

        {/* <div className="why-customers-choose-image-wrapper"> */}
        <img className="why-customers-choose-image" src={Image} alt="why customers choose"/>
        {/* </div> */}
      </div>
    </div>
  </React.Fragment>
);

export default WhyCustomersChoose;
