import React from 'react';
import { BarLoader } from 'react-css-loaders';
import './styles.css';

const Loader = () => (
  <div className="loader-container">
    <BarLoader color="#F23038" className="loader" />
  </div>
);

export default Loader;
