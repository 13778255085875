import React from "react";
import "./styles.css";

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="service-page-container">
        <div className="service-page-max-width-container">
          <div className="service-page-image-container">
            <img className="service-page-image" src={this.props.service.image} alt="Service"/>
          </div>
          <div className="service-page-text-container">
            <h1>{this.props.service.title}</h1>
            <h2>{this.props.service.subtitle}</h2>
            <p dangerouslySetInnerHTML={{__html: this.props.service.description}}></p>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicePage;
