import React from 'react';

import Header from '../../components/Header';

import Footer from '../../components/Footer';
import LocationsPage from '../../containers/LocationsPage';

const LocationsScreen = () => (
  <div>
    <Header/>
    <LocationsPage/>
    <Footer/>
  </div>
);

export default LocationsScreen;
