import React from "react";
import "./styles.css";

import Welcome from "./Welcome";
import WhyCustomersChoose from "../../components/WhyCustomersChoose";

const AboutUsPage = () => (
  <React.Fragment>
    <Welcome />
    <WhyCustomersChoose/>
  </React.Fragment>
);

export default AboutUsPage;
